import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, MAINNET, TESTNET } from "./chains";

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  [MAINNET]: {
    // bsc mainnet
    Treasury: "0xa44E7252a0C137748F523F112644042E5987FfC7",
    BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    GMT: "0x99e92123eB77Bc8f999316f622e5222498438784",
    Vault: "0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B",
    Router: "0xD46B23D042E976F8666F554E928e0Dc7478a8E1f",
    Reader: "0x087A618fD25c92B61254DBe37b09E5E8065FeaE7",
    AmmFactory: "0xBCfCcbde45cE874adCB698cC183deBcF17952812",
    AmmFactoryV2: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
    OrderBook: "0x1111111111111111111111111111111111111111",
    OrderBookReader: "0x1111111111111111111111111111111111111111",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDG: "0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7",
    NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    XGMT: "0xe304ff0983922787Fd84BC9170CD21bF78B16B10",
    GMT_USDG_PAIR: "0xa41e57459f09a126F358E118b693789d088eA8A0",
    XGMT_USDG_PAIR: "0x0b622208fc0691C2486A3AE6B7C875b4A174b317",
    GMT_USDG_FARM: "0x3E8B08876c791dC880ADC8f965A02e53Bb9C0422",
    XGMT_USDG_FARM: "0x68D7ee2A16AB7c0Ee1D670BECd144166d2Ae0759",
    USDG_YIELD_TRACKER: "0x0EF0Cf825B8e9F89A43FfD392664131cFB4cfA89",
    XGMT_YIELD_TRACKER: "0x82A012A9b3003b18B6bCd6052cbbef7Fa4892e80",
    GMT_USDG_FARM_TRACKER_XGMT: "0x08FAb024BEfcb6068847726b2eccEAd18b6c23Cd",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd8E26637B34B2487Cad1f91808878a391134C5c2",
    XGMT_USDG_FARM_TRACKER_XGMT: "0x026A02F7F26C1AFccb9Cba7C4df3Dc810F4e92e8",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x22458CEbD14a9679b2880147d08CA1ce5aa40E84",
    AUTO: "0xa184088a740c695E156F91f5cC086a06bb78b827",
    AUTO_USDG_PAIR: "0x0523FD5C53ea5419B4DAF656BC1b157dDFE3ce50",
    AUTO_USDG_FARM: "0xE6958298328D02051769282628a3b4178D0F3A47",
    AUTO_USDG_FARM_TRACKER_XGMT: "0x093b8be41c1A30704De84a9521632f9a139c08bd",
    AUTO_USDG_FARM_TRACKER_NATIVE: "0x23ed48E5dce3acC7704d0ce275B7b9a0e346b63A",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  [TESTNET]: {
    // bsc testnet
    Vault: "0x1B183979a5cd95FAF392c8002dbF0D5A1C687D9a",
    Router: "0x10800f683aa564534497a5b67F45bE3556a955AB",
    Reader: "0x98D4742F1B6a821bae672Cd8721283b91996E454",
    AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
    AmmFactoryV2: "0x1111111111111111111111111111111111111111",
    OrderBook: "0x9afD7B4f0b58d65F6b2978D3581383a06b2ac4e9",
    OrderBookReader: "0x0713562970D1A802Fa3FeB1D15F9809943982Ea9",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDG: "0x2D549bdBf810523fe9cd660cC35fE05f0FcAa028",
    GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
    NATIVE_TOKEN: "0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2",
    XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
    GMT_USDG_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
    XGMT_USDG_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
    GMT_USDG_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
    XGMT_USDG_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
    USDG_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
    XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
    GMT_USDG_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
    XGMT_USDG_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  [ARBITRUM_TESTNET]: {
    // arbitrum testnet
    Vault: "0xBc9BC47A7aB63db1E0030dC7B60DDcDe29CF4Ffb",
    Router: "0xe0d4662cdfa2d71477A7DF367d5541421FAC2547",
    VaultReader: "0xFc371E380262536c819D12B9569106bf032cC41c",
    Reader: "0x2E093c70E3A7E4919611d2555dFd8D697d2fC0a1",
    GlpManager: "0xD875d99E09118d2Be80579b9d23E83469077b498",
    RewardRouter: "0x0000000000000000000000000000000000000000",
    RewardReader: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
    GLP: "0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    GMX: "0x0000000000000000000000000000000000000000",
    ES_GMX: "0x0000000000000000000000000000000000000000",
    BN_GMX: "0x0000000000000000000000000000000000000000",
    USDG: "0xBCDCaF67193Bf5C57be08623278fCB69f4cA9e68",
    ES_GMX_IOU: "0x0000000000000000000000000000000000000000",
    StakedGmxTracker: "0x0000000000000000000000000000000000000000",
    BonusGmxTracker: "0x0000000000000000000000000000000000000000",
    FeeGmxTracker: "0x0000000000000000000000000000000000000000",
    StakedGlpTracker: "0x0000000000000000000000000000000000000000",
    FeeGlpTracker: "0x0000000000000000000000000000000000000000",

    StakedGmxDistributor: "0x0000000000000000000000000000000000000000",
    StakedGlpDistributor: "0x0000000000000000000000000000000000000000",

    GmxVester: "0x0000000000000000000000000000000000000000",
    GlpVester: "0x0000000000000000000000000000000000000000",

    OrderBook: "0xebD147E5136879520dDaDf1cA8FBa48050EFf016",
    OrderBookReader: "0xC492c8d82DC576Ad870707bb40EDb63E2026111E",

    PositionRouter: "0xB4bB78cd12B097603e2b55D2556c09C17a5815F8",
    PositionManager: "0x168aDa266c2f10C1F37973B213d6178551030e44",

    // UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
  },
  [ARBITRUM]: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    GlpManager: "0x321F653eED006AD1C29D174e17d96351BDe22649",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    GLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    GMX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ES_GMX: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_GMX: "0x35247165119B69A40edD5304969560D0ef486921",
    USDG: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeGmxTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedGlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeGlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedGmxDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedGlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    GmxVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    GlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
    PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

    UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",
  },
  // [AVALANCHE]: {
  //   // avalanche
  //   Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
  //   Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
  //   VaultReader: "0x66eC8fc33A26feAEAe156afA3Cb46923651F6f0D",
  //   Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
  //   GlpManager: "0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F",
  //   RewardRouter: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
  //   RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
  //   NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
  //   GLP: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
  //   GMX: "0x62edc0692BD897D2295872a9FFCac5425011c661",
  //   ES_GMX: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
  //   BN_GMX: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
  //   USDG: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
  //   ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

  //   StakedGmxTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
  //   BonusGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
  //   FeeGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
  //   StakedGlpTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
  //   FeeGlpTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

  //   StakedGmxDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
  //   StakedGlpDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

  //   GmxVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
  //   GlpVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",

  //   OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
  //   OrderExecutor: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
  //   OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

  //   PositionRouter: "0xffF6D276Bc37c61A23f06410Dce4A400f66420f8",
  //   PositionManager: "0xA21B83E579f4315951bA658654c371520BDcB866",

  //   TraderJoeGmxMaticPool: "0x0c91a070f862666bbcce281346be45766d874d98",
  //   ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
  //   ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",
  // },
  
  [AVALANCHE]: {
    // avalanche
    Vault: "0x980C3Cb15a66F1A026A7E562C222242a1cd30aD6",//replaced 0x9e34FDE4Bf55061a1805C11654C21acCd34d511E
    Router: "0x41BAB46bD4094653241cddDEC95F9360F8cF1219",//replaced 0x6A154CE91003Cf4b8787280fd7C96D9BFb3f88C3
    VaultReader: "0xeb39Bc66734BBE74e46D19640a24fa2e1F48250c",//replaced 0x9DFEbCA2fa5318093016892dC772CC7132dDcE43
    Reader: "0x1F53a302CD2EE95F71a33CA2fa7b625969f503f2",//replaced 0x8D88Aa3F648e751f989E528a32577Ee434B8e47F
    GlpManager: "0x67de9EbE4b17687Cd95Fa5DbE4B5065D7B09fDbA",//replaced 0x3a417b2949d59B129e5C6c0A52114335C780B9AE
    RewardRouter: "0x05eA153Df967ccfa85d23F467Bc696D01BD5dD3e",//replaced 0x0AB63435EbA15CCedb44d86Cf3e2f1d8DBeB9e08
    RewardReader: "0x9CCBA031D76f87607c75da997D5938937e320173",//replaced 0x4Ee70052E9c64602Ab96a2A61850E72A051bbd89
    NATIVE_TOKEN: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    GLP: "0x21D6B429166aCC6e4858216Dc137287B597C8090",//replaced 0xA63FbC76dDaf2F800B3699a4a46C5f260E04050C
    GMX: "0x9e5C4900ca4e8995CD00c2eDCFaa4Ab5D340Df96", //replaced 0x39E1Da9a034Fd5ADba01C7F6cFA8B5dE16dD908c
    ES_GMX: "0xD54fcAc5BF0C89B8ED742EE127e0e8Fbef0371fF",//replaced 0x6CdEf99C74CcF3FA65211fF547Be5dDB4A73770C
    BN_GMX: "0x3a7969635f470b9Ac05c8c545d11a7CbaecDaaEf",//replaced 0x6e29e6db1Ea778fCC17BA575C8fB22A4dfeAE08f
    USDG: "0xBAbD566ac8bA3720794B05e8da7f0d76bE9EEbDd", //replaced 0x775003195F41AFa4f75F751c35F06B3dF76F8e04
    ES_GMX_IOU: "0x1990CfaF416DAf9f41faA48Ee65D73eAE782A6D2", // placeholder address

    StakedGmxTracker: "0xaaf885dB484e55F459378C01c8C5B8925a2C2073",//replaced 0x48d7f4529f6149c5EB96AeF38534b90AD7562b4d
    BonusGmxTracker: "0x86a2f9DD0C131225840Aa1876D6579E64165dab3",//replaced 0xC5fcC14560781C4c9FD55d7466d781539177A3a4
    FeeGmxTracker: "0x620262b1e33AE3132dc1689CA274556042A0E778",//replaced 0xb31018C29500a565e511a0800dA87e1457CdE9b1
    StakedGlpTracker: "0xd4Ad1E56f89A4fCD8353dF355343098837239863",//replaced 0x8b498C45465f4a7e9CEc0D12Aa6a695A6b563A34
    FeeGlpTracker: "0xacE33f27a278f110CCcAedaD77481DE1598793b1",//replaced 0x82b84dc1A46D43747496E62BBEE2c70Ef8EE4EAD

    StakedGmxDistributor: "0xa7256d891F294866Bc060c764C1B3c15b66C798B",//replaced 0x4e11F35A9c226be709078787cC44375FD7c22424
    StakedGlpDistributor: "0x3854c62fc9019068FE31b977E081dc6Cb81E39bb",//replaced 0x7ed80C511359db0E34e2FbF14aB12Ee9AfAB0Baa

    GmxVester: "0xb0B08C64B4f00872af4821bC2A86CA92E01f407F",//replaced 0x957C9a17fc5A5Fda190D1Bc4Fc1AF2B6282C2743
    GlpVester: "0x6b1411508D80D5C29933bF2b2c171B54B83657e9",//replaced 0xcf920DC4df556267A82783c4647cbe9Ce55cB1A2

    OrderBook: "0x542B4bc46aA51edD56c06498378C196Baa649473",//replaced 0xA76fB4882bcb66fBe68948B71eBe7f3B80e329Ea
    OrderExecutor: "0x542B4bc46aA51edD56c06498378C196Baa649473",//replaced
    OrderBookReader: "0xC86c6286A0efc2D79Bc8b6b5A5727c2df6DC737f",//replaced 0x1d47908DC429b00611bd11AF78A7D39E7Dc6bCa2

    PositionRouter: "0xEfb22f7D00041aD0a7f82a52aBf627a145854DA3",//replaced 0xD3D403595ccBB99537Af13099aC89A102AAd70D1
    PositionManager: "0xb0b7bA65e670b79cA2AB7c0394096423eCF7a9F6",//replaced 0xD5326A526f78667375D9D5dA7C739e261Df52fe6

    TraderJoeGmxMaticPool: "0xFFAf1517877B8C326DF408Ba6B62aB1505999029",//replaced
    ReferralStorage: "0x61BB5ee47e33CEb626A2919A09c52cc74C0Db1E1",//replaced 0xa4Ac7025c01e8fa2bfB7f274913e76b5d4d719de
    ReferralReader: "0x34E74f1BCcDF92Cb58c14f22A42E4ab51784E887",//replaced 0xDe0D6DbCCB35b92e0952C51ddDD81C7A86C5E480
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
